.messages-container {
  display: flex;
  width: 100vw;
  min-height: 100vh;
}

.message-deleted {
  color: gray;
  font-style: italic;
}

.menu-messages {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 18%;
  background: white;
  border-right: 1px solid black;
}

.conversation-title {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 1vh;
  margin-top: 3vh;
  font-size: 2rem;
}

.nouvelle-conversation {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.nouvelle-conversation button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding-bottom: 1vh;
}

.nouvelle-conversation img {
  width: 1.7vw;
  height: auto;
  vertical-align: middle;
}

.conversation-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 5vh;
  border: 2px solid whitesmoke;
  border-radius: 10px;
  margin-bottom: 2vh;
}

.conversation-search input {
  border: none;
  width: 90%;
  height: 100%;
}

.conversation-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  width: 100%;
}

.conversation-card {
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 95%;
  height: 15vh;
  background-color: transparent;
  transition: background-color 0.5s ease;
}

.conversation-card:hover {
  background-color: whitesmoke;
}

.conversation-active {
  background-color: rgb(226, 219, 219);
}

.conversation-text-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1vw;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conversation-name {
  text-transform: capitalize;
  height: 5vh;
  margin-top: 1vh;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conversation-name h2 {
  font-size: 2vw !important;
  z-index: 1000;
}

.conversation-time {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 5vw;
  right: 0;
  bottom: 0;
  margin-right: 1vh;
  font-size: 0.8rem;
  font-style: italic;
}

.conversation-last-message {
  display: flex;
  height: 5vh;
  align-items: center;
  width: 98%;
  margin-bottom: 1vh;
}

.conversation-last-message p {
  font-size: 1.3rem;
  overflow: hidden;

  text-overflow: ellipsis;
}

.conversation-text h2 {
  font-size: 1.5rem;
}

.conversation-pictures img {
  background: white;
  border-radius: 50%;
  width: 10vh;
  height: 10vh;
  margin-left: 2vh;
  margin-top: 1vh;
  border: 1px solid black;
}

.conversation-messages {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;

  background: white;
}

.background-image {
  position: absolute;
  opacity: 0.4;
  width: 35%;
  top: 25%;
  height: 35%;
  /* object-fit: cover; */
  z-index: 1;
}

.background-image img {
  width: 20%;
}

.conversation-chat {
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* gap: 1vh; */
  width: 100%;
  height: 95vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: black white;
  scrollbar-arrow-color: red;
  scrollbar-track-color: transparent;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.message {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  margin-left: 1vw;
  margin-top: 1.5vh;
}

.message-received p {
  background: rgb(221, 214, 214);
  padding: 1.5vh 1.5vh;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: auto;
  line-height: 2.5vh;
  max-width: 60%;
  z-index: 2;
}

.message-sent {
  display: flex;
  justify-content: flex-end;
}

.message-sent p {
  max-width: 70%;
  background: black;
  color: white;
  padding: 1.5vh 1.5vh;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: auto;
  line-height: 2.5vh;
  z-index: 2;
  text-align: right;
}

.message-input {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 2px solid rgb(197, 195, 195);
  border-radius: 2px;
}

.message-input input {
  height: 100%;
  border: none;
  width: 100%;
  padding: 0 0 0 2rem;
}

input:focus {
  outline: none;
}

.message-input button {
  height: 100%;
  width: 10%;
  background: none;
  border: 1px solid whitesmoke;
  transition: background 0.5s ease;
  cursor: pointer;
}

.message-input button img {
  width: 2.5vw;
  height: auto;
}

.message-input {
  background: whitesmoke;
}

.profile-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 15%;
  border-left: 1px solid black;
}

.profile-author {
  font-size: 1.8rem;
  text-transform: capitalize;
}

.profile-picture {
  display: flex;
  justify-content: center;
}

.profile-picture img {
  margin: 0;
  margin-top: 5vh;
  background: white;
  border-radius: 50%;
  width: 9vw;
  height: 9vw;
  border: 1px solid black;
}

.profile-buttons {
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: var(--gap-small);
  margin-bottom: var(--gap-large);
}

.follow-button {
	padding: var(--padding-small) var(--padding-medium);
	background-color: var(--secondary-color);
	color: var(--text-light-color);
	border: none;
	border-radius: var(--border-radius);
	cursor: pointer;
	font-size: var(--font-size-base);
	transition: background-color var(--transition-duration);
  }

  .follow-button:hover {
	background-color: var(--cta-button-background);
  }

  .follow-button + .follow-button {
	margin-left: var(--gap-small);
  }

.profile-buttons:hover {
  background: whitesmoke;
}

.profile-buttons img {
  max-width: 1vw;
}

.profile-button {
  display: flex;
  width: 80%;
}

.profile-button button {
  color: white;
  font-size: larger;
  width: 12vw;
  background: black;
  height: 4vh;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
}

.select-conv {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.create-conversation-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: whitesmoke;
  gap: 1vh;
  width: 25vw;
  border-radius: 8px;
  height: 50vh;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 300px;
  max-width: 100%;
  height: auto;
  text-align: center;
  height: 100% !important;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 100%;
}

.modal-content select {
  width: 100%;
  height: 20vh;
  border: 2px solid whitesmoke;
  border-radius: 10px;
  padding-left: 1vh;
  margin: 0 !important;
  scrollbar-width: thin;
}

.modal-content input {
  width: 60%;
  align-self: center;
}

.modal-content select option {
  display: flex;
  align-items: center;
  height: 3vh;
  border-bottom: 1px solid whitesmoke;
}

.new-chat-search {
  width: 95%;
}

.new-chat-search input {
  width: 100%;
  height: 5vh;
  border: 2px solid whitesmoke;
  border-radius: 10px;
  padding-left: 1vh;
}

.search-users {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  width: 100%;
  height: 8vh;
  border-bottom: 1px solid black;
  transition: background 0.5s ease;
}

.search-user:hover {
  background: whitesmoke;
}

.search-user-name {
  display: flex;
  align-items: center;
}

.search-user-right img {
  width: 1vw;
}
.search-user-picture img {
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  margin-right: 1vh;
}

.search-user-left {
  display: flex;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.message-modal {
  position: fixed;
  display: flex;
  padding: 0 2vh 0 1vh;
  gap: 1vh;
  z-index: 1000;
  background: whitesmoke;
  width: auto;
  height: 5vh;
  border-radius: 10px;
}

.message-modal img {
  width: 1.5vw;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.message-modal img:hover {
  transform: scale(1.3);
}

.close-message-button {
  position: absolute;
  top: 1px;
  right: 2px;
  font-size: 20px;
  cursor: pointer;
}

.delete-conversation-modal {
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.edit-container {
  position: relative;
}

.edit-container-text {
  display: flex;
  justify-content: flex-end;
}

.edit-container-text textarea {
  max-width: 70%;
  background: black;
  color: white;
  padding: 1.5vh 1.5vh;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: auto;
  line-height: 2.5vh;
  z-index: 2;
  text-align: right;
  font-size: 1vw;
  font-weight: thin;
  font-family: var(--font-type);
}

.edit-container-buttons {
  position: absolute;
  left: -1vw;
  display: flex;
  gap: 1vh;
}

.edit-container-buttons button {
  background: whitesmoke;
  width: 1.6vw;
  height: 1.6vw;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.picker {
  position: absolute;
  bottom: 50px;
}

.flex-middle {
  display: none !important;
}
