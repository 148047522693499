/* Conteneur principal */
.create-project-container {
	background-color: var(--modal-background-color);
	padding: var(--padding-large);
	box-shadow: var(--modal-box-shadow);
	border-radius: var(--border-radius-large);
	width: 90%;
	width: 600px;
	margin: auto;
	font-family: var(--font-family);
	transition: all var(--transition-duration);
	display: flex;
	flex-direction: column;
	gap: var(--gap-large);
  }

  /* Titre du formulaire */
  .create-project-container h2 {
	font-size: var(--font-size-great);
	color: var(--primary-color);
	margin-bottom: var(--gap);
	text-align: center;
	font-weight: 600;
	letter-spacing: 1px;
	border-bottom: 2px solid var(--secondary-color);
	padding-bottom: var(--gap-small);
  }

  /* Style des groupes de champs */
  .form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: var(--gap-medium);
	position: relative;
  }

  .form-group label {
	font-size: var(--font-size-base);
	color: var(--text-color);
	font-weight: 500;
	margin-bottom: var(--gap-small);
	text-transform: uppercase;
  }

  /* Style des champs de saisie et de sélection */
  .form-group input,
  .form-group select,
  .form-group textarea {
	width: 100%;
	padding: var(--padding-small);
	border: 2px solid #ddd;
	border-radius: var(--border-radius);
	font-size: var(--font-size-base);
	color: var(--text-color);
	background-color: var(--background-color);
	transition: border-color var(--transition-duration), box-shadow var(--transition-duration);
  }

  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
	border-color: var(--secondary-color);
	box-shadow: 0 0 8px rgba(186, 144, 21, 0.3);
	outline: none;
  }

  .form-group textarea {
	resize: vertical;
	min-height: 120px;
  }

  /* Style des boutons de soumission */
  button[type="submit"] {
	background-color: var(--secondary-color);
	color: var(--text-light-color);
	padding: var(--padding-medium);
	border: none;
	border-radius: var(--border-radius-large);
	cursor: pointer;
	font-size: var(--font-size-large);
	font-weight: bold;
	text-transform: uppercase;
	transition: background-color var(--transition-duration), transform var(--transition-duration);
	width: 100%;
  }

  button[type="submit"]:hover {
	background-color: var(--accent-color);
	transform: translateY(-2px);
	box-shadow: var(--box-shadow-hover);
  }

  /* Bouton de fermeture */
  .close-button-modal {
	margin-top: var(--gap-large);
	text-align: center;
  }

  .close-button-modal button {
	background-color: var(--error-color);
	color: var(--text-light-color);
	padding: var(--padding-small);
	border: none;
	border-radius: var(--border-radius);
	cursor: pointer;
	width: 100px;
	font-size: var(--font-size-base);
	font-weight: 600;
	text-transform: uppercase;
	transition: background-color var(--transition-duration);
  }

  .close-button-modal button:hover {
	background-color: var(--secondary-color);
  }

  /* Transition et état général */
  .create-project-container,
  button[type="submit"],
  .close-button-modal button {
	transition: all var(--transition-duration);
  }

  /* Responsive Design */
  @media (max-width: 768px) {
	.create-project-container {
	  width: 95%;
	  padding: var(--padding);
	}

	button[type="submit"] {
	  font-size: var(--font-size-base);
	  padding: var(--padding-small);
	}
  }
