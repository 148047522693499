/* Conteneur de la sidebar */
.filter-sidebar-container {
	position: relative;
	display: flex;
	align-items: flex-start;
	transition: width 0.3s ease;
	background-color: var(--primary-color);
  }

  .filter-sidebar-container.open {
	min-width: 350px;
  }

  .filter-sidebar-container.closed {
	width: 30px;
	background-color: rgba(255, 255, 255, 0);
  }

  /* Bouton de rétraction / expansion */
  .filter-sidebar-toggle.open {
	position: absolute;
	right: -5px;
	border: none;
	color: var(--text-light-color);
	background-color: var(--primary-color);
	z-index: 1000;
	cursor: pointer;
	font-size: 24px;
	transition: transform 0.3s ease;
	border-radius: 25%;
	padding: 5px;
	background-color: rgba(255, 255, 255, 0);
  }

  .icon-close {
	font-size: 40px;
  }

  .filter-sidebar-toggle.closed {
	position: absolute;
	right: -20px;
	border: none;
	color: var(--text-color);
	background-color: rgba(255, 255, 255, 0);
	z-index: 1000;
	cursor: pointer;
	font-size: 24px;
	transition: transform 0.3s ease;
	border-radius: 25%;
	padding: 5px;
  }

  /* Contenu de la sidebar (affiché seulement si open) */
  .filter-sidebar-content {
	opacity: 1;
	transition: opacity 0.3s ease;
	padding: var(--padding-small);
	width: 100%;
	color: var(--text-light-color);
  }

  .filter-sidebar-container.closed .filter-sidebar-content {
	opacity: 0;
	pointer-events: none;
  }

  /* Sections du filtre */
  .filter-sidebar-section {
	margin-bottom: var(--gap-large);
  }

  .filter-sidebar-section h4 {
	font-size: var(--font-size-base);
	margin-bottom: var(--gap-small);
	color: var(--accent-color);
  }

  .filter-sidebar-options {
	display: flex;
	flex-direction: column;
	gap: var(--gap-small);
  }

  .filter-sidebar-options label {
	display: flex;
	align-items: center;
	gap: var(--gap-small);
	font-size: var(--font-size-small);
	color: var(--text-light-color);
  }

  .filter-sidebar-section input[type="text"] {
	width: 100%;
	padding: var(--padding-small);
	border: 1px solid var(--secondary-color);
	border-radius: var(--border-radius);
	background-color: var(--background-color);
  }

  .button-tags {
	display: flex;
	flex-wrap: wrap;
	gap: var(--gap-small);
	padding: var(--padding-small);
  }


  .button-tags:hover {
	background-color: var(--button-background-hover);
	color: var(--text-color);
  }

  .button-tags button {
	background-color: var(--button-background);
	padding: var(--padding-small) var(--padding);
	font-size: var(--font-size-base);
	border: none;
	border-radius: var(--border-radius);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: var(--gap-small);
  }

  .button-tags button:hover {
	color: var(--text-color);
  }

  .clear-tags {
  }
