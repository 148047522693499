/* Global styles */
.landing-page {
	font-family: var(--font-family);
	background-color: var(--background-color);
	color: var(--text-color);
	line-height: 1.6;
  }

  /* Header */
  .landing-header {
	background-color: var(--header-background);
	color: var(--header-text-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--padding);
	box-shadow: var(--box-shadow);
	position: sticky;
	top: 0;
	height: 10vh;
	z-index: 1000;
  }

  .landing-header .logo {
	font-size: var(--font-size-great);
	font-weight: bold;
  }

  .landing-header nav ul {
	display: flex;
	gap: var(--gap-large);
	list-style: none;
  }

  .landing-header nav a {
	color: var(--nav-link-color);
	text-decoration: none;
	padding: var(--padding-small);
  }

  .landing-header nav a:hover {
	color: var(--nav-link-hover-color);
  }

/* Hero section */
.landing-hero {
	display: flex;
	justify-content: space-evenly; /* Espace proportionnel entre le texte et l'image */
	align-items: center;
	padding: var(--padding-large);
	background-color: var(--hero-background-color);
	color: var(--hero-text-color);
	min-height: 90vh; /* Adapter la hauteur pour occuper une part significative de l'écran */
}

.hero-content {
	flex: 1; /* Le texte prend plus de place */
	max-width: 45%; /* Ajuster la largeur pour qu'elle soit équilibrée par rapport à l'image */
	padding-right: var(--gap-large); /* Un peu d'espace entre le texte et l'image */
}

.hero-content h1 {
	font-size: var(--font-size-great);
	margin-bottom: var(--gap-large);
}

.hero-content p {
	font-size: var(--font-size-large);
	margin-bottom: var(--gap);
}

.hero-button {
	padding: var(--padding);
	background-color: var(--cta-button-background);
	color: var(--cta-button-color);
	text-decoration: none;
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	transition: box-shadow var(--transition-duration), transform var(--transition-duration);
}

.hero-button:hover {
	box-shadow: var(--box-shadow-hover);
	transform: scale(1.05);
}

.hero-image {
	flex: 1; /* L'image prend également de l'espace pour un bon équilibre */
	max-width: 45%; /* Ajuster la taille pour éviter que l'image prenne trop de place */
}

.hero-image img {
	width: 100%;
	border-radius: var(--border-radius-large);
	box-shadow: var(--box-shadow);
}


  /* Banner section */
  .landing-banner {
	background-color: var(--cta-background);
	color: var(--cta-text-color);
	text-align: center;
	padding: var(--padding-large);
	margin: var(--gap-large) 0;
  }

  .landing-banner h2 {
	font-size: var(--font-size-great);
	margin-bottom: var(--gap);
  }

  .landing-banner p {
	font-size: var(--font-size-large);
  }

  /* How It Works section */
  .landing-how-it-works {
	padding: var(--padding-large);
	text-align: center;
  }

  .steps {
	display: flex;
	justify-content: space-around;
	gap: var(--gap-large);
	margin-top: var(--gap-large);
  }

  .step {
	max-width: 30%;
	text-align: center;
  }

  .step img {
	width: 100%;
	border-radius: var(--border-radius);
	margin-bottom: var(--gap);
  }

  /* Carousel section */
  .landing-carousel {
	padding: var(--padding-large);
	background-color: var(--feature-background);
	color: var(--feature-text-color);
	text-align: center;
  }

  .carousel {
	display: flex;
	gap: var(--gap);
	overflow-x: auto;
	padding: var(--gap);
  }

  .carousel-item {
	flex: 0 0 auto;
	width: 300px;
	height: 200px;
	background-color: var(--card-background);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
  }

  .carousel-item img {
	width: 100%;
	height: 100%;
	border-radius: var(--border-radius);
  }

  /* FAQ section */
  .landing-faq {
	padding: var(--padding);
	background-color: var(--background-color);
  }

  .faq-item {
	margin-bottom: var(--gap-large);
  }

  .faq-item h3 {
	font-size: var(--font-size-large);
	margin-bottom: var(--gap-small);
  }

  /* Testimonials section */
  .landing-testimonials {
	background-color: var(--feature-background);
	color: var(--feature-text-color);
	padding: var(--padding-large);
	text-align: center;
  }

  .testimonial-carousel {
	display: flex;
	gap: var(--gap);
	overflow-x: auto;
  }

  .testimonial {
	flex: 0 0 auto;
	max-width: 400px;
	background-color: var(--card-background);
	padding: var(--padding);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
  }

  /* Footer */
  .landing-footer {
	background-color: var(--footer-background);
	color: var(--footer-text-color);
	padding: var(--padding);
	text-align: center;
  }

  .landing-footer ul {
	list-style: none;
	display: flex;
	justify-content: center;
	gap: var(--gap-large);
  }

  .logo {
	font-weight: bold;
	height: 100%;
  }

  /* Marketing Banner */
.landing-marketing-banner {
	display: flex;
	justify-content: center;
	padding: var(--padding-large);
	background-color: var(--background-color);
	margin-bottom: var(--gap-large);
  }

  .target-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
  }

  .target-item {
	position: relative;
	width: 33%;
	overflow: hidden;
	transition: width 0.4s ease, transform 0.3s ease;
  }

  .target-item img {
	width: 100%;
	height: auto;
	border-radius: var(--border-radius-large);
	object-fit: cover;
	transition: transform 0.4s ease;
  }

  .target-item.active img {
	transform: scale(1.05);
  }

  .target-item:hover {
	width: 50%;
	border-radius: var(--border-radius-large);
  }

  .target-info {
	position: absolute;
	bottom: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: var(--padding-small);
	border-radius: var(--border-radius);
	opacity: 0;
	transition: opacity 0.3s ease;
	width: calc(100% - 20px);
  }

  .target-item.active .target-info {
	opacity: 1;
  }

  .target-info h3 {
	margin: 0;
	font-size: var(--font-size-large);
  }

  .target-info p {
	margin-top: var(--gap-small);
	font-size: var(--font-size-base);
  }
