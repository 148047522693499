.create-post-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 35vw;
  margin: 0 auto;
}

.create-post-container h2 {
  margin-bottom: 20px;
}

.create-post-container form > div {
  margin-bottom: 15px;
}

.create-post-container label {
  display: block;
  margin-bottom: 5px;
}

.create-post-container input,
.create-post-container select,
.create-post-container textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-post-container textarea {
  resize: vertical;
}

.create-post-container button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.search-input-container {
  display: flex;
}

.search-input-container input {
  width: 80%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-input-container button {
  width: 25%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: goldenrod;
  font-weight: 700;
  color: black;
}

.photo-username {
  display: flex;
  align-items: center;
  gap: 1vw;
  padding-left: 1vw;
  width: 70%;
  background: goldenrod;
  border-radius: 8px;
}

.photo-username p {
  font-size: 1vw;
}

.photo-username img {
  width: 2vw;
  border-radius: 50%;
}

.minus-button img {
  width: 2vw;
}

.add-button img {
  width: 2vw;
}

.search-users {
  position: relative;
  margin-top: 20px;
  display: flex;
  gap: 2vw;
}

.search-users img {
  width: 1.5vw;
}

.search-users li {
  display: flex;
  justify-content: space-between;
  gap: 1vw;
}

.added-users {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-top: 2vh;
}

.added-users img {
  width: 1.5vw;
}

.added-users li {
  display: flex;
  justify-content: space-between;
}

.modal-upload-content {
}
