/* General Container */
.profile-container {
    width: 100%;
    background-color: var(--background-color);
    padding: var(--padding);
    font-family: var(--font-family);
}

/* Profile Banner Section */
.profile-banner-profile {
    position: relative;
    height: 200px;
    background-color: var(--primary-color); /* Utilisation de la couleur principale */
    border-radius: var(--border-radius-large);
    margin-bottom: var(--gap-large);
    box-shadow: var(--box-shadow);
}

.profile-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
}

.profile-picture-min {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid var(--background-color);
    position: absolute;
    bottom: -75px;
    left: 20px;
    box-shadow: var(--box-shadow-hover);
}

.profile-picture-min img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

/* Profile Content Section */
.profile-content {
    margin-left: 200px;
    margin-top: var(--gap-large);
    color: var(--text-color);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
}

.profile-header h2 {
    font-size: var(--font-size-large);
    font-weight: bold;
    color: var(--text-color); /* Texte blanc pour le contraste avec la bannière */
}

.profile-header input {
	font-size: var(--font-size);
	font-weight: bold;
	color: var(--text-color);
	padding: var(--padding-small);
}

.user-job-title, .user-location {
    font-size: var(--font-size-base);
    color: var(--icon-color);
    margin-top: var(--gap-small);
}

.profile-stats {
	display: flex;
	justify-content: space-evenly;
	margin: var(--gap-large) 0;
	text-align: center;
	font-size: var(--font-size-base);
  }

.profile-stats p {
    margin-right: var(--gap);
    font-size: var(--font-size-base);
    color: var(--text-color);
}

.profile-stats span {
    font-weight: bold;
    color: var(--accent-color);
}

/* Buttons */
.profile-actions {
    display: flex;
    margin-top: var(--gap-small);
}

.btn {
    padding: var(--padding-small) var(--padding);
    border-radius: var(--border-radius);
    margin-right: var(--gap-small);
    cursor: pointer;
    transition: background-color var(--transition-duration);
}

.primary-btn {
    background-color: var(--secondary-color); /* Bouton principal en couleur secondaire */
    color: var(--text-light-color);
    border: none;
}

.secondary-btn {
    background-color: transparent;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
}

/* Bio Section */
.bio-section {
    background-color: var(--card-background);
    padding: var(--padding);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-top: var(--gap-large);
}

.bio-section h3 {
    margin-bottom: var(--gap-small);
    color: var(--text-color);
    font-size: var(--font-size-large);
}

.bio-section p {
    font-size: var(--font-size-base);
    color: var(--text-color);
}


.bio-section textarea {
	width: 100%;
	font-size: var(--font-size-base);
	color: var(--text-color);
	resize: none;
	padding: var(--padding-small);
}

/* Tabs Section */
.profile-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: var(--gap);
    border-bottom: 1px solid var(--card-border);
}

.tab-button {
    padding: var(--padding-small) var(--padding);
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: var(--gap-small);
    transition: background-color var(--transition-duration);
    font-size: var(--font-size-base);
    color: var(--secondary-color);
}

.tab-button.active {
    background-color: var(--accent-color); /* Utilisation de la couleur d'accent pour l'onglet actif */
    color: var(--text-light-color);
    border-radius: var(--border-radius);
}

.tab-button:hover {
    background-color: var(--accent-color);
    color: var(--text-color);
    border-radius: var(--border-radius);
}

/* Posts and Projects Section */
.posts-section, .projects-section {
    background-color: var(--card-background);
    padding: var(--padding);
    margin-top: var(--gap-large);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

/* Search Bar */
.search-bar {
    display: flex;
    align-items: center;
    margin-bottom: var(--gap);
    padding: var(--padding-small);
    background-color: var(--hero-background-color);
    border-radius: var(--border-radius);
}

.search-bar .search-icon {
    margin-right: var(--gap-small);
    color: var(--icon-color);
}

.search-bar input {
    border: none;
    outline: none;
    background: none;
    font-size: var(--font-size-base);
    width: 100%;
    color: var(--text-color);
}

/* Cards */
.posts-map-profile, .projects-map-profile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: var(--gap-large);
}

.postCards-profile, .projectCards-profile {
    width: 320px;
    margin: var(--gap-small);
    box-shadow: var(--box-shadow-hover);
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--card-background);
    transition: transform var(--transition-duration);
}

.postCards-profile:hover, .projectCards-profile:hover {
    transform: scale(1.02);
}

/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity var(--modal-transition-duration);
}

.modal-upload-content {
    background-color: var(--modal-background-color);
    padding: var(--padding);
    border-radius: var(--border-radius);
    box-shadow: var(--modal-box-shadow);
}

.banner-image {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: var(--border-radius);
	margin-bottom: var(--gap);
}

.edit-btn {
    background-color: var(--secondary-color);
    color: var(--text-light-color);
    border: none;
    padding: 5px 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: none;
	position: relative;
	top: 0px;
	right: 0px;
}

.profile-content:hover .edit-btn, .bio-section:hover .edit-btn {
    display: inline-block;
}

.edit-save-btns.editing{
	height: 80px;
	padding : var(--padding);
	display: flex;
	justify-content: flex-end;
}

.edit-save-btns{
	display: none;
}


.profile-edit-toggle {
	height: 80px;
	padding : var(--padding);

	/* flex align center */
	display: flex;
	justify-content: flex-end;
}
