.select-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: var(--padding);
  /* background-color: var(--background-color); */
}

.select-bar-option {
  background-color: var(--button-background);
  padding: var(--padding-small) var(--padding);
  font-size: var(--font-size-base);
  color: var(--text-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.select-bar-option:hover {
	background-color: var(--button-background-hover);
	color: var(--text-color);
}

.select-bar-option.selected {
	/* background-color: var(--secondary-color); */
	border: 1px solid var(--secondary-color);
	background-color: var(--button-background-hover);
	color: var(--secondary-color);
}
