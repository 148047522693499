.home-topbox {
    position: fixed;
    top: var(--padding);
    right: 0;
    display: flex;
    gap: 1vw;
    align-items: center;
    width: 9vw;
    height: 4.3vh;
    padding-right: var(--padding);
}

.little-topbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10vw;
    height: 4.3vh;
    cursor: pointer;
}

.icon-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.icon-wrapper {
    position: relative;
    display: inline-block;
}

.badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8em;
    font-weight: bold;
    z-index: 0;
}

.message-icon,
.notification-icon {
    color: #000000;
    cursor: pointer;
    font-size: 1.5em;
    text-align: center;
    align-items: center;
    height: 100%;
}

.notification-icon:hover,
.message-icon:hover {
    color: #E8C96C;
}

.msg-side-open {
    width: 13vw;
    background-color: var(--background-color);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 15px;
    margin-top: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    font-size: 1.8rem;
}

.msg-side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--secondary-color);
    padding-bottom: 10px;
    margin-bottom: 15px;
    color: var(--text-color);
	width: 100%;
}

.msg-side-header p {
    margin: 0;
}

.msg-side-header img {
    cursor: pointer;
}

.tabs {
    display: flex;
    justify-content: space-between;
}

.tabs button {
    background-color: transparent;
    border: none;
    font-size: 0.5em;
    cursor: pointer;
    padding: 5px 10px;
}

.tabs button.active {
    font-weight: bold;
    border-bottom: 2px solid #947D03;
}

.msg-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;
}

.msg-input-container input[type="text"] {
    width: 70%;
    height: 30px;
    padding: 5px;
    border: 1px solid #CED4DA;
    border-radius: 5px 0 0 5px;
}

.msg-input-container button {
    background-color: var(--secondary-color);
    color: #F8F9FA;
    border: none;
    padding: 5px 10px;
    height: 30px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.menu-card {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
	border-bottom: 1px solid var(--secondary-color);
}

.menu-card img {
    border-radius: 50%;
    margin-right: 10px;
}

.full-window-message-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.full-window-message-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.full-window-message-modal-close button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.full-window-message-modal-close img {
    width: 30px;
    height: 30px;
}

.notification-card {
    background-color: #343A40;
    color: #F8F9FA;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: transform 0.2s ease, background-color 0.2s ease;
    cursor: pointer;
    z-index: 10000;
}

.notification-card:hover {
    background-color: #212529;
    transform: translateY(-2px);
}

.notification-card p {
    margin: 5px 0;
}

.notification-card .timestamp {
    font-size: 0.8em;
    color: #CED4DA;
}

.msg-side {
    height: 100%;
    width: 33%;
}

.msg-side-close {
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
}

.msg-side-close:hover {
    background-color: #aaaaaa;
}

.msg-side-content {
    flex: 1;
    height: 100%;
	width: 100%;
    overflow-y: auto;
}

.menu-messages-content h4 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap; /* Empêche le retour à la ligne */
    overflow: hidden; /* Cache tout le texte qui déborde */
    text-overflow: ellipsis; /* Ajoute les ... pour indiquer le texte coupé */
    width: 100%; /* Assure que la largeur est limitée */
}


.menu-messages-content p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--text-color);
	text-overflow: ellipsis;
}

.fermer img {
    width: 1.5vw;
}

.chat-windows-container {
    position: fixed;
    bottom: 0px;
    right: 0vw;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    pointer-events: none;
}

.msg-open .chat-windows-container {
    position: fixed;
    bottom: 0px;
    right: 13vw;
}

.status-indicator {
    position: relative;
    bottom: -20px;
    right: 25px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid green; /* Pour ajouter une bordure blanche autour */
}

/* Couleur verte si l'utilisateur est en ligne */
.status-indicator.online {
    background-color: green;
}

/* Couleur grise si l'utilisateur est hors ligne */
.status-indicator.offline {
    background-color: rgb(218, 217, 217);
}

.textbox{
	/* Gerer l'overflow du text*/
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
