body {
	font-family: Arial, sans-serif;
	background-color: #F8F9FA;
	color: #212529;
	margin: 0;
	padding: 0;
  }

  .login-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-color: #F8F9FA;
  }

  .login-header {
	text-align: center;
	margin-bottom: 20px;
  }

  .login-header h3 {
	font-size: 1.5em;
	color: #343A40; /* Dark Gray */
  }

  .login-body {
	background-color: #FFFFFF;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	text-align: center;
	width: 100%;
	max-width: 400px;
  }

  .login-logo {
	width: 100px;
	margin-bottom: 20px;
  }

  .login-form h2 {
	font-size: 1.8em;
	margin-bottom: 20px;
	color: #343A40; /* Dark Gray */
  }

  .login-form input {
	width: 100%;
	padding: 10px;
	margin: 10px 0;
	border: 1px solid #CCC;
	border-radius: 5px;
  }

  .login-form button {
	width: 100%;
	padding: 10px;
	background-color: #E8C96C; /* Light Yellow */
	color: #212529; /* Dark Gray Text */
	border: none;
	border-radius: 5px;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }

  .login-form button:hover {
	background-color: #D4B16A; /* Slightly darker yellow */
  }

  .login-form button:disabled {
	background-color: #AAA;
  }

  .login-form .remember {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
  }

  .login-form .remember input {
	margin-right: 10px;
  }

  .login-form .signup-link {
	margin-top: 20px;
	font-size: 0.9em;
  }

  .login-form .signup-link a {
	color: #343A40; /* Dark Gray */
	text-decoration: none;
  }

  .login-form .divider {
	display: flex;
	align-items: center;
	margin: 20px 0;
  }

  .login-form .divider span {
	flex: 1;
	height: 1px;
	background-color: #CCC;
	position: relative;
  }

  .login-form .divider span::before {
	content: "ou";
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #FFFFFF;
	padding: 0 10px;
	color: #AAA;
  }

  .google-login-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 10px;
	border: 1px solid #CCC;
	border-radius: 5px;
	background-color: #FFFFFF;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }

  .google-login-button:hover {
	background-color: #F1F1F1;
  }

  .google-login-button img {
	width: 20px;
	margin-right: 10px;
  }

  .google-login-button span {
	color: #343A40; /* Dark Gray */
  }

  .login-footer {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	background-color: #343A40; /* Dark Gray */
	color: #F8F9FA; /* Light Gray */
	width: 100%;
	position: absolute;
	bottom: 0;
  }

  .login-footer-left, .login-footer-right {
	display: flex;
	flex-direction: column;
	align-items: center;
  }

  .login-footer-left p, .login-footer-right p {
	margin: 5px 0;
	color: #E8C96C; /* Light Yellow Text */
  }

  .remember {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	justify-content: center;
  }

  .remember label {
	width: 100%;
  }
