/* Conteneur principal du formulaire */
.fill-profil-container {
    background-color: var(--modal-background-color);
    font-family: var(--font-family);
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-large);
    height: 100vh;
    width: 100%;
    padding: var(--padding-large);
}

/* Style global du formulaire */
.fillform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-large);
    width: 100%;
    height: 100%;
    padding: var(--padding-large);
    border-radius: var(--border-radius-large);
}

/* Titre principal */
.fill-profil-container h2 {
    font-size: var(--font-size-large);
    color: var(--text-color);
    margin-bottom: var(--gap-large);
    text-align: center;
}

/* Étape du formulaire */
.fillstep {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
    width: 100%;
    height: auto;
}

.fillstep h1 {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: var(--gap);
    width: 100%;
    height: auto;
    font-size: var(--font-size-great); /* Adaptation pour un meilleur affichage des titres */
}

/* Section de sélection des rôles */
.role-selection {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: var(--gap-large);
    width: 100%;
    flex-wrap: wrap;
    gap: var(--gap-large); /* Ajout d'espace entre les cartes */
}

.role-selection button {
    background-color: var(--card-background);
    color: var(--text-color);
    border: 1px solid var(--card-border);
    border-radius: var(--border-radius-large);
    padding: var(--padding);
    cursor: pointer;
    font-size: var(--font-size-large);
    text-align: center;
    box-shadow: var(--box-shadow);
    width: 300px;
    height: 450px;
    transition: transform var(--transition-duration), box-shadow var(--transition-duration), background-color 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-top: 10px solid var(--secondary-color);
}

.role-selection button:hover {
    transform: scale(1.05);
    border: 100% solid var(--secondary-color);
    box-shadow: var(--box-shadow-hover);
    background-color: var(--secondary-color);
    color: var(--text-light-color); /* Assure la visibilité du texte sur hover */
}

.role-selection button.selected {
    border: 2px solid var(--secondary-color);
    box-shadow: var(--box-shadow-hover);
    transform: scale(1.05);
}

.buttonimg {
    width: 100%;
    max-width: 300px;
    height: 300px;
    object-fit: contain;
    object-position: center;
    overflow: hidden;
    margin-bottom: var(--gap-small);
}

.role-selection span {
    font-size: var(--font-size-large);
    color: var(--text-color);
    margin-top: var(--gap-large);
    text-align: center;
}

/* Style pour les champs du formulaire */
.form-group {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
    margin-bottom: var(--gap-large);
    width: 100%; /* Prend toute la largeur disponible */
}

.form-group label {
    font-size: var(--font-size-base);
    color: var(--text-color);
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: var(--padding-small);
    border: 1px solid var(--card-border);
    border-radius: var(--border-radius);
    font-size: var(--font-size-base);
    background-color: var(--background-color);
    color: var(--text-color);
    transition: box-shadow var(--transition-duration);
    width: 100%;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
    box-shadow: var(--box-shadow-hover);
    outline: none;
}

.form-group input[type="file"] {
    padding: 0;
}

/* Boutons pour les actions */
.form-actions {
    display: flex;
    justify-content: space-between;
    gap: var(--gap-large);
    width: 100%;
}

.form-actions button {
    background-color: var(--card-background);
    color: var(--text-color);
    border: 1px solid var(--card-border);
    border-radius: var(--border-radius-large);
    padding: var(--padding-small);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    text-align: center;
    font-size: var(--font-size-base);
    width: 150px;
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.form-actions button:hover {
    transform: scale(1.05);
    box-shadow: var(--box-shadow-hover);
}

.form-actions button[type="submit"] {
    background-color: var(--cta-button-background);
    color: var(--cta-button-color);
    border: 2px solid var(--cta-button-background);
}

.form-actions button[type="submit"]:hover {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
}

.form-actions button[type="button"] {
    background-color: var(--primary-color);
    color: var(--text-light-color);
    border: 2px solid var(--primary-color);
}

.form-actions button[type="button"]:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

/* Conteneur principal du formulaire */
.fill-profil-container {
    background-color: var(--modal-background-color);
    font-family: var(--font-family);
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-large);
	height: 100%;
	width: 100%;
}

/* Icône de modification */
.edit-icon {
    font-size: 1.5rem;
    color: var(--secondary-color);
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.edit-icon:hover {
    color: var(--accent-color);
}

.editable-field {
    display: flex;
    align-items: center;
}

/* General Layout */
.step-2-container {
    width: 100%;
    background-color: #f4f4f4;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.edit-icon-button {
	cursor: pointer;
	text-align: center;
	transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

/* Cache l'input tout en le rendant cliquable */
.hidden-input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer; /* Permet de montrer le curseur de clic */
}

/* Style du conteneur d'édition d'image */
.edit-icon-button {
    position: relative;
    cursor: pointer;
}

.edit-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    border: 2px solid var(--background-color);
    color: var(--primary-color);
}
