.user-card {
	background-color: var(--card-background);
	border: var(--card-border);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	transition: box-shadow var(--transition-duration);
	overflow: hidden;
	width: 100%;
	max-width: 400px;
	margin: auto;
	font-family: var(--font-family);
}

.user-card:hover {
	box-shadow: var(--box-shadow-hover);
}

.user-banner {
	width: 100%;
	height: 150px;
	overflow: hidden;
}

.banner-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.user-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: var(--padding);
	background-color: var(--modal-background-color);
}

.user-avatar {
	width: var(--avatar-size);
	height: var(--avatar-size);
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid var(--secondary-color);
	margin-top: -50px;
}

.avatar-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.user-details {
	text-align: center;
	margin-top: var(--gap-small);
}

.user-username {
	color: var(--text-color);
	font-size: var(--font-size-large);
	font-weight: bold;
	margin-bottom: var(--gap-small);
}

.user-role {
	color: var(--accent-color);
	font-size: var(--font-size-base);
	margin-bottom: var(--gap-small);
}

.user-email {
	color: var(--text-color);
	font-size: var(--font-size-small);
	margin-bottom: var(--gap-small);
}

.user-subscription {
	color: var(--highlight-color);
	font-size: var(--font-size-base);
	font-weight: bold;
}
