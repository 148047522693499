/* Signup.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #F8F9FA;
    padding: 20px;
}

.signup-content {
    background-color: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.signup-header h3 {
    font-size: 1.2em;
    color: #212529;
    text-align: center;
    margin-bottom: 20px;
}

.signup-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-body img {
    width: 100px;
    margin-bottom: 20px;
}

.signup-form {
    width: 100%;
}

.signup-form h2 {
    font-size: 1.5em;
    color: #212529;
    text-align: center;
    margin-bottom: 20px;
}

.signup-form input,
.signup-form select {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    font-size: 1em;
}

.signup-form button {
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #947D03;
    color: #F8F9FA;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s ease;
}

.signup-form button:disabled {
    background-color: #C4C4C4;
    cursor: not-allowed;
}

.signup-form button:hover:not(:disabled) {
    background-color: #b68d07;
}

.signup-form p {
    text-align: center;
    margin: 10px;
}

.signup-form a {
    color: #947D03;
    text-decoration: none;
    transition: color 0.3s ease;
}

.signup-form a:hover {
    color: #b68d07;
}

.signup-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
}

.signup-footer-left,
.signup-footer-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.signup-footer p {
    margin: 5px 0;
    color: #212529;
}
