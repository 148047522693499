/* PostCard.css */

body {
  background: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.post-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  /* border: var(--card-border); */
  border-radius: var(--border-radius);
  padding: var(--padding-small);
  text-decoration: none;
  color: inherit;
  max-width: 600px;
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-duration) ease-in-out;
}

.post-card:hover {
  transform: scale(1.0085);
}

.post-card-large {
  width: 100%;
}

.post-card-small {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-small);
}

.post-card .post-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.post-content {
  height: 35vh;
}

.post-card .post-like-button {
  flex-shrink: 0;
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap);
}

.post-user-container {
  display: flex;
  margin-right: 15px;
}


.post-author-info {
  display: flex;
  flex-direction: column;
}

.post-title {
  position: absolute;
  color: var(--text-light-color);
  text-decoration-color: rgb(117, 115, 108);
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: var(--border-radius-small);
  padding: 4px;
  top: 5px;
  left: 5px;
  font-size: 1.4em;
  margin: 0;
  font-weight: 200;
  transition: opacity 2s ease;
}

.post-title.hidden {
  opacity: 0;
}

.post-tag {
  background-color: var(--hero-background-color);
  color: var(--hero-text-color);
  border-radius: var(--border-radius-small);
  padding: 5px 10px;
  display: inline-block;
  margin-top: 5px;
  font-size: 0.9em;
}

.post-date {
  color: #6c757d;
  font-size: 0.8em;
  margin-top: 5px;
}

.TextContainer {
  font-size: 1em;
  text-align: center;
}

.post-media {
  max-width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}

.VideoContainer,
.AudioContainer,
.post-media {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-small);
  object-fit: cover;
}

.post-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
  margin-bottom: 0;
}

.post-footer p {
  color: var(--secondary-color);
  font-size: 1.2em;
  font-weight: bold;
}

.post-like-button {
  background-color: var(--card-background);
  color: var(--secondary-color);
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  font-weight: bold;
  width: auto;
  transition: background-color var(--transition-duration) ease;
}

.post-footer p {
  color: var(--accent-color);
  font-size: 1.2em;
  font-weight: bold;
}

.post-footer span {
  font-size: 1em;
  color: var(--text-color);
  font-weight: 200;
}

.post-like-button p {
  color: var(--accent-color);
  font-size: 1vw;
}

.post-like-button span {
  font-size: 1em;
  color: var(--text-color);
  font-weight: 200;
}

.post-like-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: filter var(--transition-duration) ease;
}
/*
.post-like-button .liked {
  animation: heartFill 0.6s forwards;
}

@keyframes heartFill {
  0% {
    filter: none;
  }
  50% {
    filter: brightness(1.5);
  }
  100% {
    filter: brightness(1.5) sepia(1) saturate(10) hue-rotate(-50deg);
  }
} */

.post-like-button span {
  font-size: 1em;
}

.picture-author {
  display: flex;
  align-items: center;
  gap: 2vh;
}

.CiHeart {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5em;
	font-size: 1.5em;
	color: black;
	transition: color 0.3s ease;
  }

  .CiHeart.liked {
	color: red; /* Remplit le coeur avec cette couleur */
  }



/* .picture-author img {
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  border: 1px solid black;
} */

.picture-author h3 {
  font-size: 1.5vw;
}
