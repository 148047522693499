body {
	font-family: var(--font-family);
	background-color: var(--background-color);
	color: var(--text-color);
	margin: 0;
	padding: 0;
	overflow: hidden;
  }

  header {
	background-color: var(--header-background);
	padding: var(--padding-large);
	color: var(--header-text-color);
  }

  nav ul {
	list-style: none;
	padding: 0;
	display: flex;
	justify-content: space-around;
	margin: 0;
  }

  nav ul li {
	margin: 0;
  }

  nav ul li a {
	color: var(--nav-link-color);
	text-decoration: none;
	font-weight: bold;
	transition: color var(--transition-duration);
  }

  nav ul li a:hover {
	color: var(--nav-link-hover-color);
  }

  .searchbar-publications {
	position: relative;
	display: flex;
	justify-content: space-evenly;
	width:85vw;
	min-height: 40px;
  }

  .searchbar-container {
	display: flex;
	width: 80%;
  }

  .scroll-container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
	padding: var(--padding) var(--padding) 0 0;
	box-sizing: border-box;
	overflow: hidden;
	scrollbar-width: none;
	position: relative;
  }

  .infinite-scroll-container {
	flex-grow: 1;
	overflow-y: auto;
	border-radius: var(--border-radius-large);
	scrollbar-width: none;
	padding-right: 0;
  }

  .infinite-scroll-container h4 {
	padding-top: 3vh;
  }

  .infinite-scroll-container::-webkit-scrollbar {
	display: none;
  }

  .publications-grid {
	margin-top: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	gap: 3.5vh;
	scrollbar-width: none;
	scroll-behavior: smooth;
	scroll-snap-type: y mandatory;
  }

  .publications-grid a {
	width: 29%;
	min-height: 40vh;
	margin: 0 !important;
  }

  .modal,
  .modal-filter {
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	position: fixed;
  }

  .modal-content,
  .modal-filter-content {
	background: var(--modal-background-color);
	padding: var(--padding);
	border-radius: var(--border-radius-large);
	width: 80%;
	max-width: 500px;
	position: relative;
	box-shadow: var(--modal-box-shadow);
  }

  .modal-content button,
  .modal-filter-content button {
	padding: var(--padding-small) var(--padding);
	border: none;
	background-color: var(--cta-button-background);
	color: var(--cta-button-color);
	border-radius: var(--border-radius);
	cursor: pointer;
	outline: none;
	margin-top: var(--gap-small);
	transition: background-color var(--transition-duration);
  }

  .modal-content button:hover,
  .modal-filter-content button:hover {
	background-color: var(--hero-button-background);
  }

  .close-button,
  .close-filter-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: var(--font-size-large);
	cursor: pointer;
	color: var(--text-color);
  }

  .submit-cancel {
	display: flex;
	justify-content: space-between;
	margin-top: var(--gap-large);
  }

  #submit {
	background-color: var(--accent-color);
	color: var(--text-color);
	border: none;
	padding: var(--padding-small) var(--padding);
	border-radius: var(--border-radius);
	cursor: pointer;
	font-size: 1vw;
  }

  #cancel {
	background-color: var(--text-color);
	color: var(--accent-color);
	border: none;
	padding: var(--padding-small) var(--padding);
	border-radius: var(--border-radius);
	cursor: pointer;
	font-size: 1vw;
  }

  .input-wrapper {
	  position: relative;
	  width: 100%;
  }

  .clear-icon {
	  position: absolute;
	  right: 10px;
	  top: 50%;
	  transform: translateY(-50%);
	  font-size: var(--font-size-base);
	  color: #aaa;
	  cursor: pointer;
	  transition: color var(--transition-duration);
  }

  .clear-icon:hover {
	  color: var(--text-color);
  }

  .navbar {
	  display: flex;
	  align-items: center;
	  background-color: var(--header-background);
	  padding: var(--padding-small) var(--padding);
	  border-radius: var(--border-radius-large);
  }

  .dropdown-content {
	  display: block;
	  position: absolute;
	  top: 100%;
	  left: 0;
	  border-radius: var(--border-radius-large);
	  box-shadow: var(--box-shadow-hover);
	  padding: var(--padding-small) 0;
	  min-width: 180px;
	  z-index: 1000;
  }

  .dropdown-item {
	  padding: var(--padding-small) var(--padding);
	  color: var(--text-light-color);
	  font-size: var(--font-size-base);
	  cursor: pointer;
	  transition: background-color var(--transition-duration);
  }

  .dropdown-item:hover {
	  background-color: var(--icon-color);
  }



.dropdown-item {
    padding: var(--padding-small) var(--padding);
    color: var(--text-light-color);
    font-size: var(--font-size-base);
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color var(--transition-duration);
}

.dropdown-icon {
    margin-right: var(--gap-small);
	padding: var(--padding-big);
}

.user-card {
	overflow: hidden;
	width: 100%;
	max-width: 400px;
	margin: auto;
	font-family: var(--font-family);
	cursor: pointer;
}
