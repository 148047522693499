.dash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap);
  height: 100vh;
  padding: var(--padding);
  overflow: auto;
  font-family: var(--font-family);
  background: var(--background-color);
  color: var(--text-color);
}

.dash-container::-webkit-scrollbar {
  display: none; /* Webkit */
}

.dash-container h1 {
  align-self: flex-start;
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: var(--gap);
  font-weight: bold;
  border-bottom: 2px solid var(--secondary-color);
  padding-bottom: 10px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--gap);
  width: 85%;
}

.box {
  background-color: var(--card-background);
  border: var(--card-border);
  padding: var(--padding);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform var(--transition-duration),
    box-shadow var(--transition-duration);
}

.box:hover {
  transform: translateY(-3px);
  box-shadow: var(--box-shadow-hover);
}

.box.small {
  grid-column: span 1;
  padding: var(--padding-small);
  color: var(--text-color);
}

.box.large {
  position: relative;
  grid-column: span 3;
  grid-row: span 2;
  height: 400px;
  color: var(--text-color);
}

.box.wide {
  position: relative;
  grid-column: span 4;
  height: 400px;
  color: var(--text-color);
}

.box.half {
  position: relative;
  grid-column: span 2;
  grid-row: span 2;
  height: 400px;
  color: var(--text-color);
}

.box p {
  font-size: 1.2rem;
  margin: 0;
  color: var(--primary-color);
}

.box p strong {
  color: var(--secondary-color);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--gap);
}

.chart-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--primary-color);
  text-decoration: underline;
  text-decoration-color: var(--secondary-color);
  text-decoration-thickness: 2px;
  text-underline-offset: 0.15em;
}

.chart-header select {
  width: 80%;
  align-self: center;
}

.chart-header img {
  width: 3vw;
  cursor: pointer;
}

.modal-filter {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--gap);
  max-width: 10vw;

  padding: var(--padding);
  /* background: var(--modal-background-color); */
  border: var(--card-border);
  border-radius: var(--border-radius);
  box-shadow: var(--modal-box-shadow);
  z-index: 1000;
  opacity: 0;

  visibility: hidden;
  transition: opacity var(--modal-transition-duration),
    visibility var(--modal-transition-duration);
}

.modal-filter.show {
  position: absolute;
  height: auto;

  visibility: visible;
}

.show {
  padding: 5px;
  border-radius: 8px;

  width: 10vw;
  top: 0;
  position: absolute;
  right: -10.5vw;
  background: gray;
}

.modal-content-filter-time {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.modal-content-filter-time button {
  background: black;
}

.modal-filter-large {
  position: absolute;
  background: gray;
  padding: 5px;
  border-radius: 8px;

  height: auto;
  width: 10vw;
  /* transform: translate(-50%, -50%); */
  top: 0;
  right: -10.5vw;
}

.modal-content-filter-large {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.modal-filter-large button {
  background: black;
}

.modal-filter-bar {
  position: absolute;
  background: gray;
  padding: 5px;
  height: auto;
  width: 10vw;
  top: 0;
  right: -10.5vw;
  border-radius: 8px;
}

.modal-content-filter-bar {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.modal-filter-bar button {
  background: black;
}

.modal-content-filter {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.modal-content-filter-time button {
  margin: 5px 0;
  padding: 10px 20px;
  color: var(--text-light-color);
  background: var(--primary-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-duration),
    color var(--transition-duration);
}

.modal-content-filter-time button.active {
  background-color: var(--secondary-color);
  color: var(--text-light-color);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 20px;
}

.select-post {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  width: 100%;
}

.select-post select {
  width: 50%;
  padding: var(--padding-small);
  border: none;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  color: var(--text-light-color);
  font-weight: bold;
  cursor: pointer;
}

.select-post select:focus {
  outline: none;
}

.filtre-left option {
  background: var(--secondary-color);
  color: var(--text-light-color);
}

.filtre-left {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.filtre-title-pie {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtre-title-pie img {
  position: absolute;
  left: 1vw;
}

.modal-filter-pie {
  position: absolute;
  top: 0;
  width: 10vw;
  border-radius: 8px;
  left: -10.5vw;
  background: gray;
  padding: 5px;
}

.modal-content-filter-pie {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.modal-filter-pie button {
  background: black;
}

.noData {
	height: 60vh;
}
