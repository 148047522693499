body,
html {
  overflow-x: hidden;
}

:root {
	--primary-color: #313030; /* Couleur principale pour le fond blanc */
	--secondary-color: #ba9015; /* Couleur secondaire, rouge comme YouTube */
	--background-color: #f9f9f9; /* Couleur de fond globalement clair */
	--modal-background-color: #ffffff; /* Couleur de fond des modales */
	--text-color: #0f0f0f; /* Couleur du texte principal, noir */
	--text-light-color: #ffffff; /* Couleur de texte clair pour les sous-titres */
	--icon-color: #717171; /* Couleur des icônes, noire */
	--icon-hover-color: #373737; /* Couleur des icônes, noire */
	--accent-color: #d8c969; /* Couleur d'accentuation, rouge comme YouTube */
	--link-color: #065fd4; /* Couleur des liens, bleu */
	--link-hover-color: #003ea1; /* Couleur des liens au survol, bleu foncé */


	--button-background: #d0d0d0; /* Fond des boutons blanc */
	--button-background-hover:rgb(130, 130, 130)0; /* Fond des boutons blanc */


	--box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre standard légère */
	--box-shadow-hover: 0 4px 8px rgba(0, 0, 0, 0.15); /* Ombre au survol légère */
	--modal-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Ombre des modales */

	--border-radius: 4px; /* Rayon de bordure similaire à YouTube */
	--border-radius-small: 2px; /* Rayon de bordure petite */
	--border-radius-large: 6px; /* Rayon de bordure large */

	--padding: 16px; /* Padding par défaut */
	--padding-small: 8px; /* Petit padding */
	--padding-medium: 20px; /* Padding moyen */
	--padding-large: 50px; /* Grand padding */

	--gap: 20px; /* Espace par défaut */
	--gap-small: 6px; /* Petit espace */
	--gap-large: 24px; /* Grand espace */

	--font-family: 'Roboto, Arial, sans-serif'; /* Police similaire à YouTube */
	--font-size-base: 14px; /* Taille de police de base */
	--font-size-large: 23px; /* Grande taille de police */
	--font-size-great: 45px; /* Grande taille de police */
	--font-size-small: 12px; /* Petite taille de police */

	--transition-duration: 0.2s; /* Durée de transition plus rapide */
	--modal-transition-duration: 0.3s; /* Durée de transition des modales */

	--header-background: black; /* Fond de l'en-tête blanc */
	--header-text-color: var(--text-color); /* Couleur de texte de l'en-tête noir */
	--nav-link-color: var(--text-light-color); /* Couleur des liens de navigation gris */
	--nav-link-hover-color: var(--accent-color); /* Couleur des liens de navigation au survol, rouge */

	--hero-background-color: #f1f1f1; /* Couleur de fond du héros */
	--hero-text-color: var(--text-color); /* Couleur de texte du héros */
	--hero-button-background: var(--secondary-color); /* Fond du bouton du héros rouge */
	--hero-button-color: var(--text-light-color); /* Couleur du texte du bouton du héros blanc */

	--feature-background: var(--primary-color); /* Fond des caractéristiques blanc */
	--feature-text-color: var(--text-color); /* Couleur de texte des caractéristiques noir */

	--cta-background: var(--accent-color); /* Fond du CTA rouge */
	--cta-text-color: var(--text-light-color); /* Couleur de texte du CTA blanc */
	--cta-button-background: var(--secondary-color); /* Fond du bouton CTA rouge */
	--cta-button-color: var(--text-light-color); /* Couleur de texte du bouton CTA blanc */

	--footer-background: #f1f1f1; /* Fond du pied de page gris clair */
	--footer-text-color: var(--text-color); /* Couleur de texte du pied de page noir */

	/* Spécificités techniques */
	--avatar-size: 70px; /* Taille des avatars */
	--card-background: #ffffff; /* Fond des cartes blanc */
	--card-border: 1px solid #dcdcdc; /* Bordure des cartes gris clair */
	--card-padding: 16px; /* Padding des cartes */
	--notification-background: #ffebee; /* Fond des notifications */
	--notification-border: 1px solid #ffcdd2; /* Bordure des notifications */
	--message-background: #f1f1f1; /* Fond des messages gris clair */
	--message-border-radius: 4px; /* Bordure arrondie des messages */
	--highlight-color: #ffeb3b; /* Couleur de surbrillance */
	--error-color: #d3c43b; /* Couleur d'erreur rouge */
	--success-color: #43a047; /* Couleur de succès vert */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-type);
  scrollbar-width: none;
}

.roud-avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-right: 10px;
  }
