.comment-card {
  display: flex;
  flex-direction: column;
  background: whitesmoke;
  padding: 1em;
  border-radius: 8px;
}

.comment-author img {
  width: 2vw;
}

.responses {
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  flex-direction: column;
  gap: 1vw;
  max-height: 50vh;
  overflow-y: auto;
  background: rgb(216, 208, 208);
}

.response {
  width: 100%;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  border-radius: 8px;
  transition: background 1.5s ease;
}

.response:hover {
  background: rgba(128, 128, 128, 0.452);
  border-radius: 8px;
}

.response img {
  width: 2vw;
  border-radius: 50%;
}

.response-author {
  display: flex;
  align-items: center;
  gap: 0.6vw;
}

.project-commentaires {
  max-height: 100vh;
  overflow-y: auto;
}
