/* Global Styles */
  .contact-page {
	padding: var(--padding-large);
	max-width: 800px;
	height: 100vh;
	margin: 0 auto;
	text-align: center;
  }

  .contact-page h1 {
	font-size: var(--font-size-great);
	margin-bottom: var(--gap-large);
	color: var(--text-color);
  }

  .contact-page p {
	font-size: var(--font-size-large);
	margin-bottom: var(--gap-large);
	color: var(--icon-color);
  }

  /* Form Styles */
  .contact-form {
	display: flex;
	flex-direction: column;
	gap: var(--gap-large);
  }

  .form-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  }

  .form-group label {
	font-size: var(--font-size-large);
	margin-bottom: var(--gap-small);
  }

  .form-group input,
  .form-group textarea {
	width: 100%;
	padding: var(--padding);
	font-size: var(--font-size-base);
	border: 1px solid var(--card-border);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	transition: box-shadow var(--transition-duration);
  }

  .form-group input:focus,
  .form-group textarea:focus {
	box-shadow: var(--box-shadow-hover);
	outline: none;
  }

  textarea {
	resize: vertical;
	min-height: 150px;
  }

  /* Button Styles */
  .submit-button {
	background-color: var(--cta-button-background);
	color: var(--cta-button-color);
	padding: var(--padding);
	font-size: var(--font-size-large);
	border: none;
	border-radius: var(--border-radius);
	cursor: pointer;
	box-shadow: var(--box-shadow);
	transition: box-shadow var(--transition-duration), transform var(--transition-duration);
  }

  .submit-button:hover {
	box-shadow: var(--box-shadow-hover);
	transform: scale(1.05);
  }

  .back-button {
	background-color: var(--background-color);
	font-size: var(--font-size-great);
	border: none;
	cursor: pointer;
	position: absolute;
	top: 10px;
	left: 10px;
	transition: box-shadow var(--transition-duration), transform var(--transition-duration);
  }

  /* Responsive Design */
  @media (max-width: 768px) {
	.contact-page {
	  padding: var(--padding);
	}

	.submit-button {
	  width: 100%;
	}
  }
