/* Project.css */

/* Base styles */
body {
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
  color: #212529;
  margin: 0;
  padding: 0;
}

.project-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.project-banner img {
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.project-title {
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
}

.project-title-title h2 {
  font-size: 2em;
  margin: 0;
  color: #343a40;
}

.project-title-infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.project-title-author,
.project-title-category {
  display: flex;
  align-items: center;
}

.project-title-author img,
.member-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.member-card {
  display: flex;
  background: gray;
  border-radius: 8px;
  align-items: center;
  padding: 6px;
  justify-content: space-between;
}

.member-card-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-card-left p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  font-weight: bold;
}

.member-card-right {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.member-card-right button {
  margin-right: 0 !important;
}

.project-title-author p,
.project-title-category p {
  margin: 0;
  color: #6c757d;
}

.project-description {
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
}

.project-description-title p {
  font-weight: bold;
  color: #343a40;
}

.project-progress {
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
}

.project-progress-title p {
  font-weight: bold;
  color: #343a40;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

.member-status-subscribe {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.member p,
.subscribe p,
.status p {
  margin: 0;
  color: #6c757d;
}

.subscribe button {
  background-color: #343a40;
  color: #f8f9fa;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.status img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.project-add-commentaires-title {
  display: flex;
  justify-content: space-between;
}

.project-add-commentaires {
  padding: 20px;
  border-top: 1px solid #e9ecef;
}

.project-add-commentaires-title h3,
.project-add-commentaires-title p {
  margin: 0;
}

.project-add-commentaires-content form {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.project-add-commentaires-content input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}

.project-add-commentaires-content button {
  background-color: #343a40;
  color: #f8f9fa;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.button-back {
  padding: 20px;
  text-align: center;
}

.button-back button {
  background-color: #343a40;
  color: #f8f9fa;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.button-back img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.modal-commentaires-content {
  position: fixed;
  left: 0;
  top: 0;
  width: 18.5%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.all-commentaires {
  cursor: pointer;
  color: goldenrod;
  text-decoration: underline;
}

.modal-commentaires {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeCommentairesModal button {
  background-color: #343a40;
  color: #f8f9fa;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.admin-project-modal {
  position: fixed;
  width: 75vw;
  height: 75vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 10px;
  border-radius: 8px;
  background: whitesmoke;
  border: 1px solid goldenrod;
}

.project-edit-buttons {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  height: 5vh;
  left: 0;
  width: 100%;
  background: black;
  color: goldenrod;
}

.edit-buttons button {
  background: goldenrod;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 1vw;
}
