.project-card {
	background-color: var(--card-background);
	border: var(--card-border);
	border-radius: var(--border-radius-large);
	box-shadow: var(--box-shadow);
	transition: box-shadow var(--transition-duration);
	overflow: hidden;
	width: 100%;
	max-width: 400px;
	margin: 20px auto;
	font-family: var(--font-family);
}

.project-card:hover {
	box-shadow: var(--box-shadow-hover);
}

.project-banner {
	width: 100%;
	height: 200px;
	overflow: hidden;
}

.banner-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.project-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: var(--padding-medium);
	background-color: var(--modal-background-color);
}

.project-avatar {
	width: var(--avatar-size);
	height: var(--avatar-size);
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid var(--secondary-color);
	margin-top: -35px;
}

.avatar-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.project-title {
	color: var(--text-color);
	font-size: var(--font-size-large);
	font-weight: bold;
	margin-top: var(--gap-large);
}

.project-user {
	color: var(--accent-color);
	font-size: var(--font-size-base);
	margin-bottom: var(--gap-small);
}

.project-description {
	color: var(--icon-color);
	font-size: var(--font-size-base);
	line-height: 1.5;
	margin-bottom: var(--gap-small);
	text-align: center;
}

.project-category {
	font-weight: bold;
	color: var(--secondary-color);
	margin-bottom: var(--gap-small);
}

.project-dates {
	font-size: var(--font-size-small);
	color: var(--icon-color);
	margin-bottom: var(--gap-medium);
}

.join-button {
	padding: var(--padding-small) var(--padding-medium);
	background-color: var(--secondary-color);
	color: var(--text-light-color);
	border: none;
	border-radius: var(--border-radius);
	cursor: pointer;
	transition: background-color var(--transition-duration);
}

.join-button:hover {
	background-color: var(--cta-button-background);
}
