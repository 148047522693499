.subscribe-container {
  /* background: var(--bg-gradient); */
  background: linear-gradient(135deg, #110c24 0%, #110c24 50%, #2a2053 100%);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribe-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.subscribe-title {
  font-size: 3.5rem;
  color: white;
  margin-bottom: 4rem;
  text-align: center;
}

.subscriptions {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 3vw;
}

.subscription {
  display: flex;
  flex-direction: column;
  background: whitesmoke;
  border-radius: 8px;
  width: 18%;
  height: 55vh;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
}

.subscription:hover {
  transform: scale(1.05);
  cursor: grab;
}

.plan {
  background: black;
  width: 100%;
  height: 20%;
  color: blanchedalmond;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.plan h2 {
  font-weight: 250;
  font-size: 2.5vw;
}

.plan p {
  font-size: 1vw;
  font-style: italic;
}

.price {
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
}

.price p {
  font-size: 2.5vw;
  color: navy;
  font-weight: 600;
}

.price h3 {
  font-size: 5vw;
  color: purple;
}

.price span {
  font-size: 1.5vw;
  color: navy;
}

.advantages {
  height: 35%;
  background: rgb(185, 174, 174, 0.5);
  width: 100%;
  display: flex;
  align-items: center;
}

.advantages ul {
  list-style: none;
  padding: 1rem;
  font-size: 1.3vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
}

.advantages li {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.advantages li img {
  width: 1.5vw;
}

.subscribe-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
}

.subscribe-button button {
  background: linear-gradient(135deg, #110c24 0%, #110c24 50%, #2a2053 100%);
  width: 80%;
  border-radius: 100px;
  height: 60%;
  font-size: 1.6vw;
  color: blanchedalmond;
  font-variant: small-caps;
}

.rayed-button button {
  text-decoration: line-through;
  text-decoration-color: purple;
  text-decoration-thickness: 0.13em;
  cursor: auto;
  color: blanchedalmond;
}
