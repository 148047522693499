.settings-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.settings-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.settings-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.settings-section {
    flex: 1;
    min-width: 280px;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.settings-section h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.settings-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.save-btn {
    background-color: #0066cc;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.save-btn:hover {
    background-color: #004999;
}

.manage-publications-btn,
.support-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.manage-publications-btn:hover,
.support-btn:hover {
    background-color: #218838;
}

.settings-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.settings-content p {
    margin-bottom: 1rem;
}
