.admin-project-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.admin-project-content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.candidate {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid black;
  height: auto;
  width: 30%;
  padding: 1em;
}

.member-card-right button {
  cursor: pointer;
  background: whitesmoke;
  border-radius: 50%;
  padding: 5px;
  border: none;
  margin-right: 0.5vw;
  transition: transform 0.2s ease-in-out;
}

.member-card-right button:hover {
  transform: scale(1.05);
}

.members {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid black;
  height: auto;
  width: 30%;
  padding: 1em;
}

.candidate p,
.members p {
  margin-bottom: 2vh;
  align-self: center;
}

.project-other-options {
  border-radius: 8px;
  border: 1px solid black;
  height: 100%;
  width: 30%;
}

.close {
  position: absolute;
  top: 0;
  right: 3px;
  font-size: 2vw;
}
