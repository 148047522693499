/* Container principal */
.project-list-container {
	background-color: red;
    /* background-color: var(--background-color); */
    display: flex;
    height: 100vh;
	overflow-y: auto;
}

.content-prj {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: var(--padding-large);
	gap: var(--gap-large);
}

/* Header de la page */
.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-bottom: var(--gap-large);
    align-items: center;
}

/* Zone de recherche et ajout de projet */
.search-add-project {
    display: flex;
    align-items: center;
    gap: var(--gap);
}

.search-add-project input {
    padding: var(--padding-small);
    width: 300px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    font-size: var(--font-size-base);
    box-shadow: var(--box-shadow);
    transition: border-color var(--transition-duration);
}

.search-add-project input:focus {
    border-color: var(--accent-color);
    outline: none;
}

.search-add-project button {
    padding: var(--padding-small) var(--padding);
    background-color: var(--cta-button-background);
    color: var(--cta-button-color);
    border: none;
    border-radius: var(--border-radius);
    font-size: var(--font-size-base);
    cursor: pointer;
    transition: background-color var(--transition-duration);
}

.search-add-project button:hover {
    background-color: var(--cta-background);
}

/* Les onglets */
.tabs {
    font-size: var(--font-size-great);
    width: 100%;
    max-width: 1200px;
	gap: var(--gap);
	margin-bottom: var(--gap-large);
}

.tab {
    padding: var(--padding-small) var(--padding);
    cursor: pointer;
    font-size: var(--font-size-great);
    background-color: var(--background-color);
    border-bottom: 2px solid transparent;
    transition: background-color var(--transition-duration), border-bottom var(--transition-duration);
    color: var(--text-color);
    margin: 0 var(--gap);
}

.tab.active {
    border-bottom: 2px solid var(--accent-color);
    color: var(--text-color);

}

.tab:hover {
    background-color: #e7e7e7;
}

/* Contenu des projets et collaborations */
.tab-content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-large);
    justify-content: center;
}

/* Cartes de projets ou de collaborations */
.project-card, .collaboration-card {
    background-color: var(--card-background);
    border: var(--card-border);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    transition: box-shadow var(--transition-duration), transform var(--transition-duration);
    width: 100%;
    max-width: 350px;
    margin: var(--gap-large) 0;
    padding: var(--card-padding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-card:hover, .collaboration-card:hover {
    box-shadow: var(--box-shadow-hover);
    transform: translateY(-5px);
}

/* Bannière du projet */
.project-banner img, .collaboration-banner img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: var(--border-radius-small);
    margin-bottom: var(--gap);
}

/* Informations principales */
.project-details, .collaboration-details {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
}

.project-details h3, .collaboration-details h3 {
    font-size: var(--font-size-large);
    color: var(--text-color);
}

.project-description, .collaboration-description {
    font-size: var(--font-size-base);
    color: var(--text-color);
    margin-bottom: var(--gap-small);
}

.project-category, .collaboration-category {
    font-size: var(--font-size-small);
    color: var(--icon-color);
}

.project-dates, .collaboration-dates {
    font-size: var(--font-size-small);
    color: var(--icon-color);
}

/* Footer de la carte */
.project-footer, .collaboration-footer {
    display: flex;
    justify-content: space-between;
    margin-top: var(--gap);
}

.details-button, .join-button {
    padding: var(--padding-small) var(--padding);
    font-size: var(--font-size-base);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color var(--transition-duration);
}

.details-button {
    background-color: var(--cta-button-background);
    color: var(--cta-button-color);
}

.details-button:hover {
    background-color: var(--cta-background);
}

.join-button {
    background-color: var(--secondary-color);
    color: var(--text-light-color);
}

.join-button:hover {
    background-color: var(--cta-background);
}

/* Modale */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.upload-modal {
    background-color: var(--modal-background-color);
    padding: var(--padding-large);
    border-radius: var(--border-radius-large);
    box-shadow: var(--modal-box-shadow);
    transition: opacity var(--modal-transition-duration);
	height: 80%;
	width: 80%;
	max-width: 500px;
	position: relative;
	overflow-y: auto;
}

.error-message {
    color: var(--error-color);
    font-size: var(--font-size-large);
    margin: var(--gap-large) 0;
}
