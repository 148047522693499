.chat-window {
    width: 360px;
    height: 480px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    font-family: 'Roboto', sans-serif;
    transition: height 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    z-index: 1000;
    overflow: hidden;
    pointer-events: auto;
}

.chat-window.minimized {
    height: 60px;
    cursor: pointer;
    transform: translateY(10px);
}

.chat-header {
    padding: 10px;
    background-color: var(--secondary-color);
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px 12px 0 0;
    cursor: pointer;
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
    border: 2px solid rgba(255, 255, 255, 0.7);
}

.user-name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.chat-controls {
    display: flex;
    align-items: center;
    gap: 8px;
}

.minimize-button,
.window-close-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.minimize-button:hover,
.window-close-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.chat-body {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.chat-message {
    padding: 10px 14px;
    border-radius: 10px;
    max-width: 75%;
    font-size: 15px;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.chat-message.me {
    background-color: #e6f7e3;
    align-self: flex-end;
    border-bottom-right-radius: 0;
}

.chat-message.me:hover {
    background-color: #d4f5ce;
    transform: translateY(-2px);
}

.chat-message.them {
    background-color: #f1f1f1;
    align-self: flex-start;
    border-bottom-left-radius: 0;
}

.chat-message.them:hover {
    background-color: #eaeaea;
    transform: translateY(-2px);
}

.message-content {
    display: flex;
    align-items: center;
    gap: 6px;
}

.message-timestamp {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    text-align: right;
}

.chat-footer {
    padding: 12px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
}

.chat-input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    font-size: 15px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chat-input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
    outline: none;
}

.send-button {
    padding: 10px 10px;
    background-color: var(--secondary-color);
    color: #ffffff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.send-button:hover {
    background-color: darken(var(--primary-color), 10%);
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
}

.emoji-button {
    cursor: pointer;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.3s ease;
}

.emoji-button:hover {
    color: var(--primary-color);
}

.emoji-picker {
    position: absolute;
    bottom: 60px;
    right: 15px;
    z-index: 2000;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    background-color: #ffffff;
    backdrop-filter: blur(10px);
}
