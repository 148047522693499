/* Style global de la page */
.post-unique-card {
	padding: var(--padding-medium);
	border-radius: var(--border-radius-large);
	overflow: hidden; /* Changer scroll à hidden pour éviter le scrolling non nécessaire */
	display: flex;
	justify-content: space-between;
	overflow: scroll;
}

  /* Bouton de retour */
  /* .back-button {
	background-color: var(--background-color);
	color: var(--text-color);
	border: none;
	padding: var(--padding-small);
	border-radius: var(--border-radius);
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	gap: var(--gap-small);
	transition: background-color var(--transition-duration);
  } */

  .back-button:hover {
  }

  /* Contenu principal du post */
  .post-unique-header {
	display: flex;
	flex-direction: column;
	gap: var(--gap-large);
	width: 70%;
	margin: 0 auto;
}
  /* Contenu du post (image, vidéo, texte, etc.) */
  .post-unique-content {
	position: relative;
	width: 100%;
	max-width: 854px; /* Taille maximale pour une vidéo similaire à YouTube */
	padding-top: 56.25%; /* Ratio 16:9 (9/16 = 0.5625) */
	margin: 0 auto; /* Centrer la vidéo */
	background-color: var(--card-background);
	box-shadow: var(--box-shadow-hover);
}


.post-unique-content img,
.post-unique-content video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; /* Ajuste le contenu de la vidéo à son conteneur sans déformation */
	border-radius: var(--border-radius);
}


  /* Informations sur l'auteur */
  .bloc1 {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }

  .post-unique-author-info {
	display: flex;
	flex-direction: column;
	gap: var(--gap-small);
  }

  .post-unique-title {
	font-size: var(--font-size-large);
  }

  .post-unique-tag {
	background-color: var(--accent-color);
	padding: var(--padding-small);
	border-radius: var(--border-radius-small);
  }

  .post-unique-date {
	font-size: var(--font-size-small);
	color: var(--icon-color);
  }

  /* Avatar et informations utilisateur */
  .post-unique-user-container {
	display: flex;
	align-items: center;
	gap: var(--gap);
  }

  .post-unique-author-avatar {
	width: var(--avatar-size);
	height: var(--avatar-size);
	border-radius: 50%;
	cursor: pointer;
  }

  .username-abo h1 {
	font-size: var(--font-size-base);
	cursor: pointer;
  }

  /* Bouton s'abonner / se désabonner */
  .follow-button {
	background-color: var(--cta-button-background);
	color: var(--cta-button-color);
	border: none;
	padding: var(--padding-small);
	border-radius: var(--border-radius);
	cursor: pointer;
	transition: background-color var(--transition-duration);
  }

  .follow-button:hover {
	background-color: var(--hero-button-background);
  }

  /* Menu déroulant des abonnements */
  .dropdown-menu-subscribe {
	background-color: var(--background-color);
	color: var(--text-color);
	position: absolute;
	top: 100%;
	left: 0;
	padding: var(--padding);
	border-radius: var(--border-radius);
	box-shadow: var(--modal-box-shadow);
	display: flex;
	flex-direction: column;
	gap: var(--gap-small);
	z-index: 10;
  }

  .dropdown-item {
	display: flex;
	align-items: center;
	gap: var(--gap-small);
	padding: var(--padding-small);
	cursor: pointer;
	transition: background-color var(--transition-duration);
  }

  .dropdown-item:hover {
	background-color: var(--hero-background-color);
  }

  /* Likes et vues */
  .post-unique-like-count {
	display: flex;
	align-items: center;
	gap: var(--gap-small);
  }

  .like-icon {
	width: 24px;
	height: 24px;
  }

  .post-unique-like-count p {
	font-size: var(--font-size-base);
  }

  /* Section des commentaires */
  .comment-section {
	background-color: var(--card-background);
	padding: var(--padding);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
  }

  .comments-number {
	margin-bottom: var(--gap);
	color: var(--text-color);
	font-size: var(--font-size-base);
  }

  .post-comment {
	display: flex;
	align-items: center;
	gap: var(--gap-small);
	margin-bottom: var(--gap);
  }

  .post-comment input {
	flex: 1;
	padding: var(--padding-small);
	border-radius: var(--border-radius);
	border: 1px solid var(--icon-color);
	background-color: var(--background-color);
	color: var(--text-color);
  }

  .send img {
	width: 24px;
	height: 24px;
	cursor: pointer;
  }

  .comments-list {
	display: flex;
	flex-direction: column;
	gap: var(--gap-small);
  }

  .comment {
	display: flex;
	gap: var(--gap-small);
	padding: var(--padding-small);
	background-color: var(--message-background);
	border-radius: var(--message-border-radius);
  }

  .comment-author-avatar {
	width: var(--avatar-size);
	height: var(--avatar-size);
	border-radius: 50%;
  }

  .comment-content {
	display: flex;
	flex-direction: column;
	gap: var(--gap-small);
  }

  .comment-content h3 {
	font-size: var(--font-size-base);
	color: var(--text-color);
  }

  .comment-content p {
	color: var(--icon-color);
	font-size: var(--font-size-small);
  }

  /* Liste des vidéos / publications */
  .video-scroll {
	background-color: var(--card-background);
	display: flex;
	flex-direction: column;
	gap: var(--gap-large);
	max-width: 20%; /* Limite la largeur pour laisser de la place au contenu principal */
	margin-left: auto;
}


  .video-scroll-title {
	font-size: var(--font-size-large);
	margin-bottom: var(--gap-large);
  }

  .video-scroll-card {
	padding: var(--padding);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	cursor: pointer;
	transition: box-shadow var(--transition-duration);
	width: 100%;
	max-width: 225px;
}



  .video-scroll-card:hover {
	box-shadow: var(--box-shadow-hover);
  }

  .video-scroll-card img,
  .video-scroll-card video {
	  width: 100%;
	  height: auto;
	  max-height: 140px; /* Assure que les vidéos/images ne dépassent pas une certaine hauteur */
	  object-fit: cover;
	  border-radius: var(--border-radius);
  }

  .video-scroll-card-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
  }

  .video-scroll-card-title {
	font-size: var(--font-size-base);
	color: var(--text-color);
  }

  .scroll-author-date {
	font-size: var(--font-size-small);
	color: var(--icon-color);
  }

  .content-scroll {
  }
