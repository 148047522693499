/* Footer Styles */
.site-footer {
	background-color: var(--primary-color);
	color: var(--text-light-color);
	padding: var(--padding);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: var(--box-shadow);
  }

  .footer-content {
	display: flex;
	justify-content: space-around;
	width: 100%;
	max-width: 1200px;
	margin-bottom: var(--gap-large);
  }

  .footer-section {
	flex: 1;
	padding: var(--padding-small);
  }

  .footer-section h3 {
	font-size: var(--font-size-large);
	margin-bottom: var(--gap-small);
	color: var(--accent-color);
  }

  .footer-section ul {
	list-style: none;
	padding: 0;
  }

  .footer-section ul li {
	margin-bottom: var(--gap-small);
  }

  .footer-section ul li a {
	color: var(--text-light-color);
	text-decoration: none;
	transition: color var(--transition-duration);
  }

  .footer-section ul li a:hover {
	color: var(--accent-color);
  }

  .social-icons {
	display: flex;
	justify-content: center;
	gap: var(--gap-large);
  }

  .social-icons a {
	color: var(--text-light-color);
	font-size: 1.5rem;
	transition: color var(--transition-duration);
  }

  .social-icons a:hover {
	color: var(--accent-color);
  }

  /* Newsletter Form */
  .newsletter-form {
	display: flex;
	flex-direction: column;
	gap: var(--gap-small);
  }

  .newsletter-form input {
	padding: var(--padding);
	border: 1px solid var(--card-border);
	border-radius: var(--border-radius);
	font-size: var(--font-size-base);
	background-color: var(--background-color);
	color: var(--text-color);
  }

  .newsletter-form button {
	padding: var(--padding);
	background-color: var(--cta-button-background);
	color: var(--cta-button-color);
	border: none;
	border-radius: var(--border-radius);
	font-size: var(--font-size-large);
	cursor: pointer;
	transition: background-color var(--transition-duration), transform var(--transition-duration);
  }

  .newsletter-form button:hover {
	background-color: var(--cta-button-hover-background);
	transform: scale(1.05);
  }

  /* Footer Bottom */
  .footer-bottom {
	border-top: 1px solid var(--card-border);
	padding-top: var(--padding-small);
	font-size: var(--font-size-small);
  }

  .footer-bottom p {
	margin: var(--gap-small) 0;
  }

  .footer-bottom a {
	color: var(--text-light-color);
	text-decoration: none;
	transition: color var(--transition-duration);
  }

  .footer-bottom a:hover {
	color: var(--accent-color);
  }

  /* Responsive Design */
  @media (max-width: 768px) {
	.footer-content {
	  flex-direction: column;
	  align-items: center;
	}

	.footer-section {
	  margin-bottom: var(--gap-large);
	}
  }
