.home-container {
	display: flex;
	height: 100vh;
}

.home-sidebar {
	width: 80px;
	background-color: var(--card-background);
	display: flex;
	flex-direction: column;
	align-items: center;
	border-right: var(--card-border);
	transition: all 0.3s ease;
}

.sidebar-logo {
	width: var(--avatar-size);
	height: auto;
	position: absolute;
}

.sidebar-menu {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: var(--gap);
	flex-grow: 1;
	transition: opacity 0.3s ease, visibility 0.3s ease;
}

.messaging-active .home-sidebar {
	width: 0;
	opacity: 0;
	visibility: hidden;
	border-right: none;
}

.messaging-active .home-main-content {
	width: 100%;
}

.sidebar-logo-menu {
	font-size: 1.75rem;
	color: var(--icon-color);
	margin: var(--gap) 0;
	cursor: pointer;
	width: 100%;
}

.sidebar-logo-menu:hover {
	/* color: var(--secondary-color-hover); */
	color: var(--icon-hover-color)

}

.home-main-content {
	width: calc(100% - 80px); /* Ajuster en fonction de la largeur de la Sidebar */
	background: var(--background-color);
	scrollbar-width: none; /* Suppression de la scrollbar */
	transition: width 0.3s ease; /* Ajout de la transition pour lisser l'effet */
}

/* Classe qui s'applique lorsque le MsgSide est ouvert */
.home-main-content.msg-open {
	width: calc(100% - 17%); /* Ajuster en fonction de la largeur de votre MsgSide */
}

.home-main-contener {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.home-main-contener-content {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.home-msg-side-open,
.home-msg-side-close {
	position: absolute;
	bottom: var(--gap);
	right: var(--gap);
	background-color: var(--modal-background-color);
	border-radius: 50%;
	box-shadow: var(--box-shadow);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	cursor: pointer;
	transition: all var(--transition-duration) ease;
}

.home-msg-side-open {
	transform: scale(1);
}

.home-msg-side-close {
	transform: scale(0);
}

.big-logo {
	width: 90px;
	height: auto;
}

.sidebar-logo-menu.active {
	color: var(--secondary-color);
	scale: 1.1;
}
