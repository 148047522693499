.user-miniature-container {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.profile-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.default-profile-icon {
    font-size: 40px;
    color: #aaa;
}

.profile-dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1000;
    width: 150px;
}

.dropdown-item {
	display: block;
	align-items: center;
	justify-content: center;
    cursor: pointer;
    font-size: 14px;
	background-color: var(--background-color);
	color: var(--text-color);
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;
}

.dropdown-item:last-child {
    border-bottom: none;
}

