  header {
	background-color: var(--header-background);
	padding: var(--padding-large);
	color: var(--header-text-color);
  }

  nav ul {
	list-style: none;
	padding: 0;
	display: flex;
	justify-content: space-around;
	margin: 0;
  }

  nav ul li {
	margin: 0;
  }

  nav ul li a {
	color: var(--nav-link-color);
	text-decoration: none;
	font-weight: bold;
	transition: color var(--transition-duration);
  }

  nav ul li a:hover {
	color: var(--nav-link-hover-color);
  }


  .searchbar-container {
	display: flex;
	width: 70%;
  }

  .searchbar-container input {
	padding: var(--padding-small);
	border: 1px solid #ccc;
	border-radius: 0;
	width: 100%;
	outline: none;
	box-sizing: border-box;  /* Ensure padding doesn't affect width */
	height: 100%;  /* Match height with buttons */
  }

  .searchbar-container button.recherche,
  .searchbar-publications button.recherche {
	padding: 0 var(--padding);  /* Remove vertical padding */
	border: none;
	background-color: var(--cta-button-background);
	color: var(--cta-button-color);
	border-radius: 0 var(--border-radius) var(--border-radius) 0;
	cursor: pointer;
	outline: none;
	width: auto;
	height: 100%;  /* Match height with input */
	display: flex;
	align-items: center;
	justify-content: center;  /* Center text inside the button */
	transition: background-color var(--transition-duration);
  }

  .publications-grid {
	margin-top: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	gap: 3.5vh;
	scrollbar-width: none;  /* Hide the scrollbar */
	scroll-behavior: smooth;
	scroll-snap-type: y mandatory;
  }

  .publications-grid a {
	width: 29%;
	min-height: 40vh;
	margin: 0 !important;
  }

  .modal,
  .modal-filter {
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	position: fixed;
  }

  .modal-content,
  .modal-filter-content {
	background: var(--modal-background-color);
	padding: var(--padding);
	border-radius: var(--border-radius-large);
	width: 80%;
	max-width: 500px;
	position: relative;
	box-shadow: var(--modal-box-shadow);
  }

  .modal-content button,
  .modal-filter-content button {
	padding: var(--padding-small) var(--padding);
	border: none;
	background-color: var(--cta-button-background);
	color: var(--cta-button-color);
	border-radius: var(--border-radius);
	cursor: pointer;
	outline: none;
	margin-top: var(--gap-small);
	transition: background-color var(--transition-duration);
  }

  .modal-content button:hover,
  .modal-filter-content button:hover {
	background-color: var(--hero-button-background);
  }

  .close-button,
  .close-filter-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: var(--font-size-large);
	cursor: pointer;
	color: var(--text-color);
  }

  .submit-cancel {
	display: flex;
	justify-content: space-between;
	margin-top: var(--gap-large);
  }

  #submit {
	background-color: var(--accent-color);
	color: var(--text-color);
	border: none;
	padding: var(--padding-small) var(--padding);
	border-radius: var(--border-radius);
	cursor: pointer;
	font-size: 1vw;
  }

  #cancel {
	background-color: var(--text-color);
	color: var(--accent-color);
	border: none;
	padding: var(--padding-small) var(--padding);
	border-radius: var(--border-radius);
	cursor: pointer;
	font-size: 1vw;
  }

  .input-wrapper {
	  position: relative;
	  width: 100%;
  }

  .input-wrapper input {
	  padding: var(--padding-small);
	  padding-right: 30px;  /* Space for the clear icon */
	  border: 1px solid #ccc;
	  border-radius: 0;
	  width: 100%;
	  outline: none;
	  box-sizing: border-box;  /* Ensure padding doesn't affect width */
	  height: 100%;  /* Match height with buttons */
  }

  .clear-icon {
	  position: absolute;
	  right: 10px;
	  top: 50%;
	  transform: translateY(-50%);
	  font-size: var(--font-size-base);
	  color: #aaa;
	  cursor: pointer;
	  transition: color var(--transition-duration);
  }

  .clear-icon:hover {
	  color: var(--text-color);
  }

  .navbar {
	  display: flex;
	  align-items: center;
	  background-color: var(--header-background);
	  padding: var(--padding-small) var(--padding);
	  border-radius: var(--border-radius-large);
  }

  .menu-item-container {
	  position: relative;
	  width: 9%;
  }

  .menu-item {
	  position: relative;
	  height: 100%;
	  cursor: pointer;
  }

  .menu-button {
	height: 100%;
	  border: none;
	  font-size: var(--font-size-base);
	  cursor: pointer;
	  border-radius: var(--border-radius) 0 0 var(--border-radius);
	  padding: var(--padding-small) var(--padding);
	  background-color: var(--secondary-color);
	  color: var(--cta-button-color);
	  display: flex;
	  align-items: center;
	  transition: background-color var(--transition-duration);
  }

.create-button {
	position: relative;
	height: 100%;
	padding: var(--padding-small) var(--padding);
	border: 1px solid var(--icon-color);
	flex: 1;
	background-color: var(--background-color);
	color: black;
	border-radius: var(--border-radius);
	cursor: pointer;
	font-size: 1vw;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}

.create-button:hover {
    background-color: var(--icon-hover-color);
}


.dropdown-icon {
    margin-right: var(--gap-small);
	padding: var(--padding-big);
}

.create-content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dropdown-menu {
	background-color: var(--background-color);
	color: var(--text-color);
	width: 7.5vw;
	position: absolute;
	/* en bas du composant parent */
	top: 100%;
	padding: var(--padding);
	border-radius: var(--border-radius);
	box-shadow: var(--modal-box-shadow);
	display: flex;
	flex-direction: column;
	gap: var(--gap-small);
	z-index: 10;
  }

  .poten{
	  display: flex;
	  overflow: scroll;
  }
