.posts-map {
	display: flex;
	flex-direction: row;
	gap: var(--gap);
	width: 100%;
	overflow-x: auto;
  }

  .back-profil-button {
	background-color: var(--background-color);
	font-size: var(--font-size-great);
	border: none;
	cursor: pointer;
	transition: box-shadow var(--transition-duration), transform var(--transition-duration);
  }
